import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import LoginPage from '../pages/LoginPage.vue';
import Home from "@/pages/Home.vue";
import thongkebenhnhan from "@/pages/Thongkebenhnhan.vue";
import thongkedoanhthu from "@/pages/Thongkedoanhthu.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'homenew',
    component: thongkebenhnhan,
    props: true
  },
  {
    path: '/login',
    name: 'login',
    component: LoginPage,
    props: true
  },
  {
    path: '/home',
    name: 'home',
    component: thongkebenhnhan,
    props: true
  },
  {
    path: '/thongkebenhnhan',
    name: 'thongkebenhnhan',
    component: thongkebenhnhan,
    props: true
  },
  {
    path: '/thongkedoanhthu',
    name: 'thongkedoanhthu',
    component: thongkedoanhthu,
    props: true
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router



import {computed, onMounted, ref} from 'vue'
import {useToast} from "primevue/usetoast";
import {FilterMatchMode} from "primevue/api";
import moment from "moment";
import theoDoiRepository from "@/services/TheoDoiRepository";
import {TinhTien} from "@/models/tinhTien";
import {DieuTri} from "@/models/dieuTri";
import {values} from "lodash";
import { DoanhThu } from '@/models/doanhThu';
import {DoanhThuCungKy} from "@/models/doanhThuCungKy";


export default {
  setup: function () {
    const toast = useToast();
    const date = ref();
    const doanhThuCaNam = ref([] as DoanhThu[]);
    const doanhThuCungKy = ref({} as DoanhThuCungKy);
    const tongTien = ref(0);
    const checkSelect = ref(false);
    const doanhThu = ref([] as TinhTien[]);
    const loadingBar = ref(true);


    const loadData = () => {
      theoDoiRepository.doanhThuCaNam()
          .then((response) => {
            doanhThuCaNam.value = response.data;
            loadingBar.value = false;
          })
            .catch(err => {
              toast.add({
                severity: 'error',
                summary: 'Lỗi',
                detail: err.response.data,
                life: 2000
              });
            });

      theoDoiRepository.doanhThuCungKy()
          .then((response) => {
            doanhThuCungKy.value = response.data;
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail: err.response.data,
              life: 2000
            });
          });
          }
    loadData();

    const loadDataNew = () => {
      if(!checkSelect.value)
        theoDoiRepository.listDoanhThu(0,0)
            .then((response) => {
              tongTien.value = 0;
              doanhThu.value = response.data;
              doanhThu.value.forEach(x => tongTien.value += x.tien);
            })
            .catch(err => {
              toast.add({
                severity: 'error',
                summary: 'Lỗi',
                detail: err.response.data,
                life: 2000
              });
            });
      }
    loadDataNew();
    const totalData = computed(() => {
      //console.log("################ loadData totalData: ");
      const data = doanhThuCaNam.value;
      //console.log("################ loadData data: " + data);
      return {
        labels: data.map(x => x.thang),
        datasets: [
          {
            label: 'Doanh thu năm trước',
            data: data.map(x => x.namTruoc),
            backgroundColor: '#ffdc82',
          },
          {
            label: 'Doanh thu năm nay',
            data: data.map(x => x.namNay),
            backgroundColor: '#82c4ff',
          }
        ]
      }
    });
    const basicOptions = ref(
        {
          plugins: {
            legend: {
              labels: {
                color: '#495057'
              }
            }
          },
          scales: {
            x: {
              ticks: {
                color: '#495057'
              },
              grid: {
                color: '#ebedef'
              }
            },
            y: {
              ticks: {
                color: '#495057'
              },
              grid: {
                color: '#ebedef'
              }
            }
          }
        }
    );
    const selectCalendar = () => {
      if((date.value[0] != null)&&(date.value[1] != null)) {
        theoDoiRepository.listDoanhThu(date.value[0]/1000, date.value[1]/1000)
            .then((response) => {
              tongTien.value = 0;
              doanhThu.value = response.data;
              checkSelect.value = true;
              doanhThu.value.forEach(x => tongTien.value += x.tien);
            })
            .catch(err => {
              toast.add({
                severity: 'error',
                summary: 'Lỗi',
                detail: err.response.data,
                life: 2000
              });
            });
      }
    };

    const clearCalendar = () => {
        checkSelect.value = false;
        loadData();
    }

    return {
      totalData,
      basicOptions,
      selectCalendar,
      date,
      clearCalendar,
      doanhThuCungKy,
      tongTien,
      doanhThu,
      loadingBar
    }
  }

}

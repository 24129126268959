
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useToast } from "primevue/usetoast";
import router from "@/router";

export default {
  setup() {
    const store = useStore();
    const toast = useToast();


    const homeMenu = computed(() => {
      const menu = ref([
        {
          label:'Trang chủ',
          icon:'pi pi-fw pi-home',
          to: '/home'
        },
        {
          label:'Thống kê bệnh nhân',
          icon:'pi pi-fw pi-users',
          to: '/thongkebenhnhan'
        },
        {
          label:'Thống kê doanh thu',
          icon:'pi pi-fw pi-dollar',
          to: '/thongkedoanhthu'
        },
      ]);
      return menu.value;
    })


    return {
      homeMenu,
    }
  }
}

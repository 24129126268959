

import {computed, onMounted, ref} from 'vue'
import {useToast} from "primevue/usetoast";
import {FilterMatchMode} from "primevue/api";
import moment from "moment";
import theoDoiRepository from "@/services/TheoDoiRepository";
import {TinhTien} from "@/models/tinhTien";
import {DieuTri} from "@/models/dieuTri";
import {values} from "lodash";


export default {
  setup: function () {
    const toast = useToast();
    const date = ref();
    const doanhThu = ref([] as TinhTien[]);
    const listDieuTri = ref([] as DieuTri[]);
    const tongTien = ref(0);
    const noiTru = ref(0);
    const xuatVien = ref(0);
    const vaoVien = ref(0);
    const khamBenh = ref(0);
    const checkSelect = ref(false);


    const loadData = () => {
      //console.log("################ loadData");
      if (!checkSelect.value) {
        {
          theoDoiRepository.listDieuTri(0, 0)
              .then((response) => {
                noiTru.value = 0;
                xuatVien.value = 0;
                vaoVien.value = 0;
                khamBenh.value = 0;
                listDieuTri.value = response.data;
                //console.log("################ loadData listDieuTri: " + JSON.stringify(listDieuTri.value));
                listDieuTri.value.forEach(x => {
                  if (x.choVe == 1)
                    noiTru.value += x.dangDT;
                  xuatVien.value += x.raVien;
                  vaoVien.value += x.vaoVien;
                  khamBenh.value += x.sL_kham;
                });
              })
              .catch(err => {
                toast.add({
                  severity: 'error',
                  summary: 'Lỗi',
                  detail: err.response.data,
                  life: 2000
                });
              })
              .finally(
                  function () {
                    setTimeout(loadData, 10 * 1000);
                  }
              );
        }
      }
    }
    loadData();

    const totalData = computed(() => {
      //console.log("################ loadData totalData: ");
      const data = listDieuTri.value;
      //console.log("################ loadData data: " + data);
      return {
        labels: data.map(x => x.phongBan),
        datasets: [
          {
            label: 'Tổng hợp bệnh nhân nội trú',
            data: data.map(x => x.dangDT),
            backgroundColor: '#01d7fd',
          }
        ]
      }
    });
    const totalDataIn = computed(() => {
      //console.log("################ loadData totalData: ");
      const data = listDieuTri.value;
      //console.log("################ loadData data: " + data);
      return {
        labels: data.map(x => x.phongBan),
        datasets: [
          {
            label: 'Tổng hợp bệnh nhân vào khoa',
            data: data.map(x => x.vaoVien),
            backgroundColor: '#82c4ff',
          },
          {
            label: 'Tổng hợp bệnh nhân chờ nhập khoa',
            data: data.map(x => x.sL_VaoVienNT),
            backgroundColor: '#ffdc82',
          }
        ]
      }
    });
    const totalDataOut = computed(() => {
      //console.log("################ loadData totalData: ");
      const data = listDieuTri.value;
      //console.log("################ loadData data: " + data);
      return {
        labels: data.map(x => x.phongBan),
        datasets: [
          {
            label: 'Tổng hợp bệnh nhân ra viện',
            data: data.map(x => x.raVien),
            backgroundColor: '#fd7b6a',
          }
        ]
      }
    });
    const totalDataChecking = computed(() => {
      //console.log("################ loadData totalData: ");
      const data = listDieuTri.value;
      //console.log("################ loadData data: " + data);
      return {
        labels: data.map(x => x.phongBan),
        datasets: [
          {
            label: 'Tổng hợp bệnh nhân đã khám',
            data: data.map(x => x.sL_kham),
            backgroundColor: '#d16d96',
          }
        ]
      }
    });
    const basicOptions = ref(
        {
          plugins: {
            legend: {
              labels: {
                color: '#495057'
              }
            }
          },
          scales: {
            x: {
              ticks: {
                color: '#495057'
              },
              grid: {
                color: '#ebedef'
              }
            },
            y: {
              ticks: {
                color: '#495057'
              },
              grid: {
                color: '#ebedef'
              }
            }
          }
        }
    );
    const selectCalendar = () => {
      if((date.value[0] != null)&&(date.value[1] != null)) {
        checkSelect.value = true;
        theoDoiRepository.listDieuTri(date.value[0]/1000, date.value[1]/1000)
            .then((response) => {
              noiTru.value = 0;
              xuatVien.value = 0;
              vaoVien.value = 0;
              khamBenh.value = 0;
              listDieuTri.value = response.data;
              //console.log("################ loadData listDieuTri: " + JSON.stringify(listDieuTri.value));
              listDieuTri.value.forEach(x => {
                if(x.choVe == 1)
                  noiTru.value += x.dangDT;
                xuatVien.value += x.raVien;
                vaoVien.value += x.vaoVien;
                khamBenh.value += x.sL_kham;
              });
            })
            .catch(err => {
              toast.add({
                severity: 'error',
                summary: 'Lỗi',
                detail: err.response.data,
                life: 2000
              });
            });
    }};

    const clearCalendar = () => {
        checkSelect.value = false;
        loadData();
    }

    return {
      totalData,
      basicOptions,
      totalDataOut,
      totalDataIn,
      totalDataChecking,
      doanhThu,
      tongTien,
      noiTru,
      vaoVien,
      xuatVien,
      selectCalendar,
      date,
      khamBenh,
      clearCalendar,
    }
  }

}

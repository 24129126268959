import {AxiosResponse} from "axios";
import Repository from "@/services/Repository";

const resource = '/thongke';

export default new class{
    private Repository: any;
    listDieuTri(dt1: number, dt2: number): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/benhnhan/${dt1}/${dt2}`);
    }
    listDoanhThu(dt1: number, dt2: number): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/doanhthu/${dt1}/${dt2}`);
    }
    doanhThuCungKy(): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/doanhthucungky`);
    }
    doanhThuCaNam(): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/doanhthucanam`);
    }
}